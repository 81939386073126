import React, { useState, useEffect, ReactElement } from 'react';

import MobileFilters from './shows-landing-filters--mobile';
import DesktopFilters from './shows-landing-filters--desktop';
import { Text, RadioGroup } from './shows-landing-form-fields';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { GetCachedVariables, SortMethods } from '../../apollo/cache';

const ShowsLandingFilters = (): ReactElement => {
  const { genre, source, sortBy, textInput } = GetCachedVariables();
  const sortMethods = Object.values(SortMethods);
  const isMobileLayout = useWindowWidth() < 875; // $smmd breakpoint triggers layout change

  const [filtersSelected, setFiltersSelected] = useState(false);

  // when a filter is changed/selected, we want to change the filters icon
  // on Mobile Layout, and display a "Reset All Filters" option on all layouts
  useEffect(() => {
    setFiltersSelected(
      genre.name !== 'all-genres' || source.name !== 'all-sources' || textInput !== ''
    );
  }, [genre, source, textInput]);

  return (
    <form
      className="shows-landing__form"
      action="#"
      onSubmit={(e) => e.preventDefault()}
    >
      <Text
        id="shows-landing__field__filter-by-title"
        value={textInput}
        placeholderText={isMobileLayout ? 'Search' : ''}
        labelText={isMobileLayout ? '' : 'Filter by Title:'}
        filterType="textInput"
        testId="cy-show-title-filter"
      />
      {/* Filter markup changes based on window size */}
      {isMobileLayout ? (
        <MobileFilters
          genre={genre}
          source={source}
          filtersSelected={filtersSelected}
        />
      ) : (
        <DesktopFilters
          genre={genre}
          source={source}
          filtersSelected={filtersSelected}
        />
      )}
      <RadioGroup
        id="shows-landing__field__sort-by"
        customClass="shows-landing__radio-group__sort-by"
        selected={sortBy}
        options={sortMethods}
        labelText="Sort By:"
        filterType="sortBy"
      />
    </form>
  );
}

export default ShowsLandingFilters;
