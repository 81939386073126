import { useState, useEffect, useLayoutEffect } from 'react';

import { ScrollPosition } from '../types/shows-landing';
import { getScrollPosition } from './utils';

// adapted from https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
export const useScrollPosition = (wait = 300): ScrollPosition => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    let throttleTimeout: ReturnType<typeof setTimeout> | null = null;
    const updatePosition = () => {
      const currentPosition = getScrollPosition();
      setScrollPosition(currentPosition);
      throttleTimeout = null;
    };

    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(updatePosition, wait);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [wait]);

  return scrollPosition;
};

export const useWindowHeight = (): number => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return height;
};
