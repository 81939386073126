import { ApolloClient, HttpLink } from '@apollo/client';

import { cache } from './cache';
import { getStation } from 'scripts/utils/getStation';
import { getUserId } from 'scripts/utils/getUserId';

const contentServicesGraphql = 'https://content.services.pbs.org/graphql/';

const customFetch = (url: string, params: RequestInit) => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "X-PBS-Platform": window.stationVideoPortalId ? "svp" : "pbsorg",
    "X-PBS-Platform-Version": "1.0",
    "X-PBS-Country-ID": window.PBS_COUNTRY_ID || "US",
  };

  const userId = getUserId();
  const stationId = getStation().stationId;

  // we should check for user id first;
  // if it does not exist, we should check for station id.
  // cs graphql will accept only one of these at a time.
  if (userId) {
    headers["X-PBS-User-Id"] = userId;
  } else if (stationId) {
    headers["X-PBS-Station-Id"] = stationId;
  }

  params.headers = headers;
  return fetch(url, params);
}

const link = new HttpLink({
  uri: contentServicesGraphql,
  fetch: customFetch
});

export const client = new ApolloClient({
  cache,
  link
});
