import { gql } from '@apollo/client';

export const SHOWS = gql`
  query SearchShowsQuery($first: Int!, $ordering: AllShowsOrdering!, $title: String, $genre: Genre, $source: AllShowsSource, $after: String) {
    searchShows(first: $first, ordering: $ordering, title: $title, genre: $genre, source: $source, after: $after) {
      edges {
        cursor
        node {
          cid
          description:descriptionLong
          genre
          image:poster2x3ImageUrl
          title
          slug
          website:producerUrl
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
