import React, { useState, ReactElement } from 'react';

import { RadioGroup } from './shows-landing-form-fields';
import { resetFilters } from '../../helpers/utils';
import { GenreFilters, SourceFilters } from '../../apollo/cache';
import { FiltersProps } from '../../types/shows-landing';

import FilterIcon from '../../../shared/filter-icon';
import { ReactComponent as CloseIcon } from 'svg/pbs-close.svg';

const MobileFilters = (props: FiltersProps): ReactElement => {
  const { genre, source, filtersSelected } = props;
  const genres = Object.values(GenreFilters);
  const sources = Object.values(SourceFilters);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return (
    <div className="shows-landing__mobile-layout">
      <button
        className="shows-landing__filter-button"
        onClick={() => setMobileMenuVisible(true)}
      >
        <span className="shows-landing__filter-text">Filter By:</span>
        <FilterIcon
          selected={filtersSelected}
          className="shows-landing__filter-icon"
        />
      </button>
      {mobileMenuVisible && (
        <div className="shows-landing__mobile-menu-content">
          <button
            className="shows-landing__cancel-button"
            onClick={() => setMobileMenuVisible(false)}
          >
            Cancel
          </button>
          <RadioGroup
            id="shows-landing__field__filter-by-genre"
            customClass="shows-landing__radio-group__genre"
            selected={genre}
            options={genres}
            labelText="Filter by Genre:"
            filterType="genre"
            testId="cy-show-genre-filter"
          />
          <RadioGroup
            id="shows-landing__field__filter-by-source"
            customClass="shows-landing__radio-group__source"
            selected={source}
            options={sources}
            labelText="More Filters:"
            filterType="source"
          />
          {filtersSelected && (
            <button
              className="shows-landing__reset-button"
              onClick={() => resetFilters()}
            >
              <CloseIcon className="shows-landing__reset-icon" />
              <span className="shows-landing__reset-text">
                Reset All Filters
              </span>
            </button>
          )}
          <button
            className="shows-landing__close-button btn"
            onClick={() => setMobileMenuVisible(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default MobileFilters;
