import { CachedVars, QueryVariables } from '../types/shows-landing';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const gqlTranslations: any = {
  'passport-library': 'featured-passport',
  'station-only': 'local',
  'all-sources': null,
  'all-genres': null
};

const gqlify = (param: string): string => {
  let gqlParam = param;
  if (param in gqlTranslations) {
    gqlParam = gqlTranslations[param];
  }
  if (gqlParam) {
    gqlParam = gqlParam.toUpperCase().replace(/-/g, '_')
  }
  return gqlParam;
}

export const formatVariables = (cachedVars: CachedVars): QueryVariables => {
  const { genre, source, sortBy, station, textInput } = cachedVars;

  const gqlOrdering = gqlify(sortBy.name);
  const gqlSource = gqlify(source.name);
  const gqlGenre = gqlify(genre.name);

  const variables: QueryVariables = {
    first: 50,
    ordering: gqlOrdering,
    station: station?.stationId,
    title: textInput,
    source: gqlSource,
    genre: gqlGenre
  };

  return variables;
}