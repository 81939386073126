import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export interface GridItemProps {
  show?: Show | null;
}

export interface Show {
  cid: string;
  description?: string;
  genre?: Genre | string[];
  id?: string;
  image?: string;
  title: string;
  slug?: string;
  url?: string;
}

interface Genre {
  cid: string;
  slug: string;
  title: string;
  url: string;
}
interface ShowPosterGridProps {
  shows: Array<Show>;
  gridItem: React.FC<GridItemProps>;
}

const ShowPosterGrid: React.FC<ShowPosterGridProps> = (props) => {

  const { shows, gridItem } = props;
  const GridItem = gridItem;

  const content = shows.map((show, index) => {
      return (
        <CSSTransition
          key={index}
          classNames="react-show-poster__item"
          timeout={{ enter: 500, exit: 300 }}
        >
          <GridItem show={show} key={index} />
        </CSSTransition>
      )
    });

  return (
    <TransitionGroup
      className="show-poster-grid"
      data-testid="cy-show-poster-grid"
    >
      {content}
    </TransitionGroup>
  );

}

export default ShowPosterGrid;
