import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, useReactiveVar } from '@apollo/client';

import ShowsLandingFilters from './components/filters/shows-landing-filters';
import ShowsLandingResults from './components/results/shows-landing-results-container';
import { client } from './apollo/client';
import { stationVar } from './apollo/cache';

function ShowsLanding() {
  const station = useReactiveVar(stationVar);
  const isLocalized = station.stationId && station.stationId.length > 0;

  if (!isLocalized) {
    return (
      <section className="shows-landing__app">
        <div className="shows-landing__results-container">
          <p className="shows-landing__localize-message">
            Please <strong>Choose Station</strong> above in order to browse
            shows.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="shows-landing__app">
      <ShowsLandingFilters />
      <ShowsLandingResults />
    </section>
  );
}

const container = document.getElementById('shows-landing__container');
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <ShowsLanding />
  </ApolloProvider>
);
