import React, { ReactElement } from 'react';

import { changeFilter } from '../../helpers/changeFilter';
import { ReactComponent as SearchIcon } from 'svg/pbs-search.svg';
import { TextFieldProps, RadioGroupProps, RadioOptionProps } from '../../types/shows-landing';

export const Text = (props: TextFieldProps): ReactElement => {
  const {
    id,
    value,
    placeholderText,
    labelText,
    testId,
  } = props;
  return (
    <div className="shows-landing__text-input">
      <label className="shows-landing__label" htmlFor={id}>
        {labelText}
      </label>
      <input
        id={id}
        type="text"
        value={value}
        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()} // don't allow enter key
        onChange={(e) => changeFilter('textInput', e.target.value)}
        data-testid={testId}
        placeholder={placeholderText}
      />
      <SearchIcon className="shows-landing__icon" />
    </div>
  );
}

export const RadioGroup = (props: RadioGroupProps): ReactElement => {
  const {
    id,
    customClass,
    selected,
    options,
    labelText,
    filterType,
    testId,
  } = props;
  return (
    <div className={`shows-landing__radio-group ${customClass}`}>
      <label className="shows-landing__label" htmlFor={id}>
        {labelText}
      </label>
      <div id={id} data-testid={testId}>
        {options.map(function (option: RadioOptionProps, index: number) {
          return (
            <Radio
              key={index}
              name={option.name}
              checked={selected.name === option.name}
              labelText={option.labelText}
              filterType={filterType}
            />
          );
        })}
      </div>
    </div>
  );
}

export const Radio = (props: RadioOptionProps): ReactElement => {
  const { name, checked, labelText, filterType } = props;
  return (
    <div className="shows-landing__radio-option">
      <input
        id={name}
        name={name}
        value={name}
        type="radio"
        aria-checked={checked}
        checked={checked}
        onChange={(e) => changeFilter(filterType, e.target.value)}
        data-testid={`cy-show-radio-${name}`}
      />
      <label htmlFor={name} className="shows-landing__radio-label">
        {labelText}
      </label>
    </div>
  );
}
