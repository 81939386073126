import { paramObject, paramPair, paramObjectKey } from '../types/shows-landing';

/**
 * Parses URL query string and returns Shows Landing parameters.
 */
const getParamsFromUrl = (paramString: string): paramObject => {
  const rawParams = paramString.replace(/\?/g, '');
  const parsedParams = rawParams.split('&');
  const keyValuePairs = parsedParams.map((param) => param.split('='));
  const paramsFromUrl: paramObject = {};

  keyValuePairs.forEach(function (pair: paramPair) {
    if (pair[0] === 'search') {
      paramsFromUrl['textInput'] = decodeURI(pair[1]);
    } else {
      const key: paramObjectKey = pair[0];
      const value: string = decodeURI(pair[1]);
      paramsFromUrl[key] = value;
    }
  });
  return paramsFromUrl;
};

export { getParamsFromUrl }
