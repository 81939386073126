/*
  <FilterIcon />
*/

import React from 'react';



interface FilterIconProps {
  selected: boolean;
  className: string;
}

const FilterIcon: React.FC<FilterIconProps> = (props) => {

  const { selected, className } = props;

  let classNames = 'pbs-filters';

  if (className) {
    classNames += ` ${className}`;
  }

  if (selected) {
    return (
      <svg
        className={classNames}
        width="19px"
        height="25px"
        viewBox="0 0 19 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>PBS Filters: Selected</title>
        <defs>
          <polygon
            id="path-1"
            points="1.62708875 2.11259945 6.60480278 2.11259945 6.60480278 6.05740115 1.62708875 6.05740115"
          ></polygon>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-95.000000, -180.000000)">
            <g id="Group-17" transform="translate(17.000000, 119.000000)">
              <g id="Group-11" transform="translate(78.199298, 61.500000)">
                <path
                  d="M4.05919165,1.07991673 L4.05919165,22.6782513"
                  id="Line"
                  stroke="#2638C4"
                  strokeWidth="2.283996"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M14.5275067,1.07991673 L14.5275067,22.6782513"
                  id="Line"
                  stroke="#2638C4"
                  strokeWidth="2.283996"
                  strokeLinecap="round"
                ></path>
                <g id="Group-8" transform="translate(10.027507, 3.641412)">
                  <circle
                    id="Oval"
                    fill="#FE704E"
                    cx="4.05919165"
                    cy="4.05919165"
                    r="4.05919165"
                  ></circle>
                  <mask id="mask-2" fill="white">
                    <use href="#path-1"></use>
                  </mask>
                  <g id="Clip-2"></g>
                  <path
                    d="M1.71757341,4.32387112 L3.1713181,5.94046429 C3.23871585,6.0154487 3.3357192,6.05821627 3.4366041,6.05741602 C3.53741842,6.05659308 3.63371604,6.0122376 3.69991404,5.93615931 L6.51816315,2.69708007 C6.64607773,2.55004007 6.63058683,2.32716878 6.48354682,2.19925421 C6.33654211,2.07133964 6.11367082,2.08683053 5.98575624,2.23387054 L3.42933502,5.17195355 L2.24232309,3.85198104 C2.11200902,3.70709353 1.88889072,3.69523717 1.74400321,3.82555124 C1.5991157,3.95586531 1.58725934,4.17898361 1.71757341,4.32387112"
                    id="Fill-1"
                    fill="#FFFFFF"
                    mask="url(#mask-2)"
                  ></path>
                </g>
                <circle
                  id="Oval"
                  fill="#2638C4"
                  cx="4.05919165"
                  cy="16.0575645"
                  r="4.05919165"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        className={classNames}
        width="19px"
        height="25px"
        viewBox="0 0 19 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>PBS Filters: Unselected</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" transform="translate(0.289630, 0.500000)">
            <path
              d="M4.05919165,1.07991673 L4.05919165,22.6782513"
              id="Line"
              stroke="#2638C4"
              strokeWidth="2.283996"
              strokeLinecap="round"
            ></path>
            <path
              d="M14.5275067,1.07991673 L14.5275067,22.6782513"
              id="Line"
              stroke="#2638C4"
              strokeWidth="2.283996"
              strokeLinecap="round"
            ></path>
            <g
              id="Group-8"
              transform="translate(10.027507, 3.641412)"
              fill="#2638C4"
            >
              <circle
                id="Oval"
                cx="4.43723483"
                cy="4.05919165"
                r="4.05919165"
              ></circle>
            </g>
            <circle
              id="Oval"
              fill="#2638C4"
              cx="4.05919165"
              cy="16.0575645"
              r="4.05919165"
            ></circle>
          </g>
        </g>
      </svg>
    );
  }
};

export default FilterIcon;
