import { Dispatch, SetStateAction } from 'react';

import { WindowPBS } from 'scripts/modules/window.PBS';
import { CachedVars, ScrollPosition } from '../types/shows-landing';
import { getStationShortCommonName } from 'scripts/utils/getStationShortCommonName';
import { changeFilter } from './changeFilter';

declare let window: WindowPBS;

/**
 * Helper function that handles resetting genre, source, and text filters at the same time.
 */
export const resetFilters = (): void => {
  changeFilter('genre', 'all-genres');
  changeFilter('source', 'all-sources');
  changeFilter('textInput', '');
};

/**
 * Helper function that determines whether the "Back To Top" button should display.
 */
export const shouldDisplayTopButton = (height: number, scrollPosition: {x: number, y: number}): boolean => {
  const verticalScroll = scrollPosition.y === 0 ? 0 : -scrollPosition.y;

  if (verticalScroll === 0) return false;
  if (height > 1200) return true;
  if (height + verticalScroll > 1200) return true;
  return false;
};

/**
 * Helper function that handles behavior when "Back To Top" button is clicked.
 */
export const handleTopButtonClick = (setDisplayTopButton: Dispatch<SetStateAction<boolean>>): void => {
  setDisplayTopButton(false);
  window.scrollTo(0, 0);
};

/**
 * Helper function to format the user-facing URL with search params.
 * We create this URL so that a particular search is shareable or bookmarkable.
 */
export const constructDisplayUrl = (cachedVars: CachedVars): string => {
  const { genre, source, sortBy, station, textInput } = cachedVars;
  // remove accents/diacritics and replace them inline
  // courtesy of https://stackoverflow.com/a/37511463
  const normalizedTextInput = encodeURI(
    textInput.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  );
  const displayUrl = [
    `/shows/`,
    `?search=${normalizedTextInput}`,
    `&genre=${genre.name}`,
    `&source=${source.name}`,
    `&sortBy=${sortBy.name}`,
    `&stationId=${station.stationId}`,
  ].join('');
  return displayUrl;
};

/**
 * Sets the history based on filters.
 */
export const handleHistoryUpdate = (cachedVars: CachedVars): void => {
  const displayUrl = constructDisplayUrl(cachedVars);
  const name = getStationShortCommonName();
  // Note: the title argument is ignored in most browsers, see
  // compatibilty table at https://developer.mozilla.org/en-US/docs/Web/API/History_API
  window.history.pushState({}, `All Shows | ${name}`, displayUrl);
};

// adapted from https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
export const getScrollPosition = (): ScrollPosition => {
  const isBrowser = typeof window !== `undefined`;
  const position = document.body.getBoundingClientRect();

  if (!isBrowser || !position) return { x: 0, y: 0 };

  return { x: position.left, y: position.top };
};
