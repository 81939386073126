import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import debounce from 'lodash.debounce';

const getElementTopPosition = (element: HTMLElement): number => {
  if (element && element.offsetParent) {
    return element.offsetTop + getElementTopPosition(element.offsetParent as HTMLElement);
  }
  return 0;
}

const elementIsFullyScrolled = (element: HTMLElement, offset = 150): boolean => {
  const scrolledFromTop = window.scrollY;

  const pixelsFromBottomOfElement =
    getElementTopPosition(element) +
    element.offsetHeight -
    scrolledFromTop -
    window.innerHeight;

  if (pixelsFromBottomOfElement < offset) {
    return true;
  }
  return false;
};

// based on https://upmostly.com/tutorials/build-an-infinite-scroll-component-in-react-using-react-hooks
export const useInfiniteScroll = (callback: () => void, elementId: string): (boolean | Dispatch<SetStateAction<boolean>>)[] => {
  const [shouldFetchMore, setShouldFetchMore] = useState(false);

  useEffect(() => {
    if (!shouldFetchMore) return;
    callback();
  // adding callback to the dependency array breaks infinite scroll
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchMore]);

  const handleScroll = useCallback(() => {
    // don't do anything under these conditions
    const element = document.getElementById(elementId);
    if (shouldFetchMore || !element) return;

    // if we're within [offset]px of the bottom of the element, fetch more content
    const shouldFetchMoreShows = elementIsFullyScrolled(element);
    setShouldFetchMore(shouldFetchMoreShows);
  // adding shouldFetchMore to the dependency array breaks the page in general
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandleScroll = debounce(handleScroll, 10);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [debouncedHandleScroll]);

  return [shouldFetchMore, setShouldFetchMore];
};
