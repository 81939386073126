import React, { useState, useEffect, ReactElement } from 'react';

import { RadioGroup } from './shows-landing-form-fields';
import {
  resetFilters,
  handleTopButtonClick,
  shouldDisplayTopButton,
} from '../../helpers/utils';
import { useWindowHeight, useScrollPosition } from '../../helpers/hooks';
import { GenreFilters, SourceFilters } from '../../apollo/cache';
import { FiltersProps } from '../../types/shows-landing';

import { ReactComponent as ArrowUpIcon } from 'svg/pbs-arrow-up.svg';
import { ReactComponent as CloseIcon } from 'svg/pbs-close.svg';

const DesktopFilters = (props: FiltersProps): ReactElement => {
  const { genre, source, filtersSelected } = props;
  const genres = Object.values(GenreFilters);
  const sources = Object.values(SourceFilters);
  const [displayTopButton, setDisplayTopButton] = useState(false);
  const height = useWindowHeight();
  const scrollPosition = useScrollPosition();

  // when window height or scrollPosition changes,
  // determine if we need to show the "Back To Top" button
  useEffect(() => {
    setDisplayTopButton(shouldDisplayTopButton(height, scrollPosition));
  }, [height, scrollPosition]);

  return (
    <div className="shows-landing__desktop-layout">
      <RadioGroup
        id="shows-landing__field__filter-by-genre"
        customClass="shows-landing__radio-group__genre"
        selected={genre}
        options={genres}
        labelText="Filter by Genre:"
        filterType="genre"
        testId="cy-show-genre-filter"
      />
      <RadioGroup
        id="shows-landing__field__filter-by-source"
        customClass="shows-landing__radio-group__source"
        selected={source}
        options={sources}
        labelText="More Filters:"
        filterType="source"
      />
      {filtersSelected && (
        <button
          className="shows-landing__reset-button"
          onClick={() => resetFilters()}
        >
          <CloseIcon className="shows-landing__reset-icon" />
          <span className="shows-landing__reset-text">Reset All Filters</span>
        </button>
      )}
      {displayTopButton && (
        <button
          className="shows-landing__top-button"
          onClick={() => handleTopButtonClick(setDisplayTopButton)}
        >
          <span className="shows-landing__top-button__text">Back To Top</span>
          <ArrowUpIcon className="shows-landing__top-button__icon" />
        </button>
      )}
    </div>
  );
}

export default DesktopFilters;
