import React, { useState, useEffect, ReactElement } from 'react';
import { useQuery } from '@apollo/client';

import LoadingIndicator from 'components/shared/loading-indicator';
import ShowsLandingResultsGrid from './shows-landing-results-grid';

import { GetCachedVariables } from '../../apollo/cache';
import { handleHistoryUpdate } from '../../helpers/utils';
import { formatVariables } from '../../helpers/formatVariables';
import { SHOWS } from '../../apollo/queries';
import { Node } from '../../types/shows-landing';

const ShowsLandingResults = (): ReactElement => {
  const cachedVariables = GetCachedVariables();
  const [queryVariables, setQueryVariables] = useState(formatVariables(cachedVariables));
  const [debouncedTextInput, setDebouncedTextInput] = useState(cachedVariables.textInput);
  const [shows, setShows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canFetchMorePages, setCanFetchMorePages] = useState(false);

  /**
   * Makes a query based on cached input variables.
   */
  const { loading, error, data, refetch, fetchMore } = useQuery(
    SHOWS, { 
      variables: queryVariables
    }
  );

  /**
   * Updates the cursor for the next set of results for a given query.
   */
  const fetchMoreHelper = () =>{
    fetchMore({
      variables: {
        after: data.searchShows.pageInfo.endCursor
      }
    });
  }

  /**
   * Handles updating text input, which requires a debounce to avoid excessive queries.
   * Adapted from https://github.com/apollographql/react-apollo/issues/450#issuecomment-648154768
   */
  useEffect(() => {
    // remove accents/diacritics and replace them inline
    // courtesy of https://stackoverflow.com/a/37511463
    const normalizedTextInput = cachedVariables.textInput
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const handler = setTimeout(() => {
      setDebouncedTextInput(normalizedTextInput);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [cachedVariables.textInput]);

  /**
   * Handles the effects of our query loading, returning data, or erroring.
   */
  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } 
    if (data && data.searchShows) {
      const { edges, pageInfo } = data.searchShows;
      const shows = edges.map((item: Node) => {
        return item.node;
      });
      setShows(shows);
      setCanFetchMorePages(pageInfo.hasNextPage);
      setIsLoading(false);

      // update browsing history with user-facing URL
      handleHistoryUpdate(cachedVariables);
    }
    if (error) {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  /**
   * 
   */
  useEffect(() => {
    setQueryVariables(formatVariables(cachedVariables));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedVariables.genre, cachedVariables.sortBy, cachedVariables.source, cachedVariables.station, debouncedTextInput]);


  useEffect(() => {
    setIsLoading(true);
    refetch(queryVariables);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  if (isLoading) {
    return (
      <div className="shows-landing__results-container">
        <LoadingIndicator addClass="shows-landing__loading-indicator" />
      </div>
    );
  } else if (shows.length === 0 || error) {
    return (
      <div className="shows-landing__results-container">
        <h2 className="shows-landing__no-results">
          There are no results for these filters.
        </h2>
      </div>
    );
  } else {
    return (
      <ShowsLandingResultsGrid
        shows={shows}
        canFetchMorePages={canFetchMorePages}
        fetchMore={fetchMoreHelper}
      />
    );
  }
}

export default ShowsLandingResults;
