import React, { ReactElement } from 'react';

import LoadingIndicator from 'components/shared/loading-indicator';
import Grid from 'components/shared/react-show-poster-grid';
import ShowPoster from 'components/shared/react-show-poster';

import { ShowsLandingResultsGridProps } from '../../types/shows-landing';
import { useInfiniteScroll } from '../../../../scripts/utils/useInfiniteScroll';

const ShowsLandingResultsGrid = (props: ShowsLandingResultsGridProps): ReactElement => {
  const { shows, canFetchMorePages, fetchMore } = props;

  const infiniteScrollCallback = () => {
    if (!canFetchMorePages || !shouldFetchMore) return;
    fetchMore();
  }

  const [shouldFetchMore] = useInfiniteScroll(infiniteScrollCallback, 'shows-landing__infinite-scroll');

  return (
    <div
      id="shows-landing__infinite-scroll"
      className="shows-landing__results-container"
    >
      <Grid shows={shows} gridItem={ShowPoster} />
      {canFetchMorePages && (
        <LoadingIndicator addClass="shows-landing__loading-indicator" />
      )}
    </div>
  );
}

export default ShowsLandingResultsGrid;
