import { genreFilterVar, GenreFilters, sortMethodVar, SortMethods, sourceFilterVar, SourceFilters, textInputVar } from '../apollo/cache';

/**
 * Handler for changes to filters. Updates each reactive variable in the cache.
 * This function and it's imports need to be in their own file in order ot
 * avoid circular dependencies.
 */
export const changeFilter = (filterType: string, value: string): void => {
  switch (filterType) {
    case 'genre':
      genreFilterVar(GenreFilters[value]);
      break;
    case 'sortBy':
      sortMethodVar(SortMethods[value]);
      break;
    case 'source':
      sourceFilterVar(SourceFilters[value]);
      break;
    case 'textInput':
      textInputVar(value)
      break;
    default:
      // eslint-disable-next-line no-console
      console.log('filter not found');
  }
}
