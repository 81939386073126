import { InMemoryCache, makeVar, useReactiveVar } from '@apollo/client';
import { relayStylePagination } from "@apollo/client/utilities";

import { SortMethod, SourceFilter, GenreFilter, Station, paramObject } from '../types/shows-landing';
import { getParamsFromUrl } from '../helpers/getParamsFromUrl';
import { getStation } from 'scripts/utils/getStation';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        genreFilter: {
          read () {
            return genreFilterVar();
          },
        },
        sortMethod: {
          read () {
            return sortMethodVar();
          }
        },
        sourceFilter: {
          read () {
            return sourceFilterVar();
          }
        },
        textInput: {
          read () {
            return textInputVar();
          }
        },
        station: {
          read () {
            return stationVar();
          }
        },
        searchShows: relayStylePagination(),
      }
    }
  }
});

export const SortMethods: { [filter: string]: SortMethod } = {
  'popular': {
    name: 'popular',
    labelText: 'Popular'
  },
  'alphabetical': {
    name: 'alphabetical',
    labelText: 'A-Z'
  }
}

export const SourceFilters: { [filter: string]: SourceFilter } = {
  'all-sources': {
    name: 'all-sources',
    labelText: 'All'
  },
  'passport-library': {
    name: 'passport-library',
    labelText: 'Featured Passport Shows'
  },
  'station-only': {
    name: 'station-only',
    labelText: `Only ${getStation().stationName} Shows`
  }
}

export const GenreFilters: { [filter: string]: GenreFilter } = {
  'all-genres': {
    name: 'all-genres',
    labelText: 'All'
  },
  'arts-and-music': {
    name: 'arts-and-music',
    labelText: 'Arts & Music'
  },
  'culture': {
    name: 'culture',
    labelText: 'Culture'
  },
  'drama': {
    name: 'drama',
    labelText: 'Drama'
  },
  'food': {
    name: 'food',
    labelText: 'Food'
  },
  'history': {
    name: 'history',
    labelText: 'History'
  },
  'home-and-howto': {
    name: 'home-and-howto',
    labelText: 'Home & How-To'
  },
  'indie-films': {
    name: 'indie-films',
    labelText: 'Indie Films'
  },
  'news-and-public-affairs': {
    name: 'news-and-public-affairs',
    labelText: 'News & Public Affairs'
  },
  'science-and-nature': {
    name: 'science-and-nature',
    labelText: 'Science & Nature'
  }
}

function getInitialFilterState() {
  const queryString = window.location.search; // everything after pbs.org/shows/ ...
  const paramsFromUrl: paramObject = queryString && getParamsFromUrl(queryString);
  const { genre, source, sortBy, textInput } = paramsFromUrl;
  const initialFilterState = {
    genre: GenreFilters[genre] || GenreFilters['all-genres'],
    source: SourceFilters[source] || SourceFilters['all-sources'],
    sortBy: SortMethods[sortBy] || SortMethods['popular'],
    textInput: textInput || ''
  }
  return initialFilterState;
}

const initialFilterState = getInitialFilterState();

export const genreFilterVar = makeVar<GenreFilter>(
  initialFilterState.genre
);

export const sourceFilterVar = makeVar<SourceFilter>(
  initialFilterState.source
);

export const sortMethodVar = makeVar<SortMethod>(
  initialFilterState.sortBy
);

export const textInputVar = makeVar<string>(
  initialFilterState.textInput
);

export const stationVar = makeVar<Station>(
  getStation()
);

interface CachedVariables {
    genre: GenreFilter;
    source: SourceFilter;
    sortBy: SortMethod;
    station: Station;
    textInput: string;
}

export const GetCachedVariables = (): CachedVariables => {
  const cachedVariables = {
    genre: useReactiveVar(genreFilterVar),
    source: useReactiveVar(sourceFilterVar),
    sortBy: useReactiveVar(sortMethodVar),
    station: useReactiveVar(stationVar),
    textInput: useReactiveVar(textInputVar)
  }
  return cachedVariables;
}
